@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';
/* You can add global styles to this file, and also import other style files */

$gutter: .5rem; //for primeflex grid system

/*
@import "assets/theme/orange/theme-light";
@import "assets/layout/css/layout-light";
*/

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex";
@import "../node_modules/primeicons/primeicons.css";
// @import "~@fullcalendar/common/main.min.css";
// @import "~@fullcalendar/daygrid/main.min.css";
// @import "~@fullcalendar/timegrid/main.min.css";

.clickable {
  cursor: pointer;
}

/*
    <link id="theme-css" rel="stylesheet" type="text/css" href="assets/theme/orange/theme-light.css">
    <link id="layout-css" rel="stylesheet" type="text/css" href="assets/layout/css/layout-light.css">

 */

@media print {
  p-menubar {
    display: none;
  }

  body {
    background: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  #print-warranty {
    font-size: 11px;
    height: 100vh;
  }
}
